import './App.css';
import { Link, animateScroll as scroll } from "react-scroll";
import {BiHomeAlt2, BiUser, BiBriefcase} from "react-icons/bi";
import {AiOutlineFundProjectionScreen, AiOutlinePhone} from "react-icons/ai";
import {GrLinkedinOption, GrGithub, GrMail} from "react-icons/gr";
import {TbBrandJavascript} from "react-icons/tb";
import {FaJava, FaPython, FaAws} from "react-icons/fa";
import {SiMongodb, SiMysql, SiCplusplus} from "react-icons/si";
import {DiBootstrap, DiDocker} from "react-icons/di";
import ProjCarousel from './ProjCarousel';
import ExpCarousel from './ExpCarousel';
// import emailjs from '@emailjs/browser';
import emailjs from 'emailjs-com';
import { useRef ,useEffect } from 'react';
import {TypeAnimation} from 'react-type-animation';

function App(){
  useEffect(() => {
    const removeHash = () => {
      if (window.location.hash) {
        const newURL = window.location.href.replace(/#.*$/, '');
        window.history.replaceState(null, null, newURL);
      }
    };

    removeHash();
  }, []);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_f4k3nij",
        "template_h7vsryx",
        form.current,
        "tTrA8rVOVb_5URSrk"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("SUCCESS!");
        },
        (error) => {
          console.log(error.text);
          alert("FAILED...", error);
        }
      );
  };

  return (
      <div className="App">
  
        <div className="nav">
          <div className="smallMenu">
            <div className="menuItem"><Link to='section1' smooth='true' className='navIcon'><BiHomeAlt2/></Link></div>
            <div className="menuItem"><Link to='section2' smooth='true' className='navIcon'><BiUser/></Link></div>
            <div className="menuItem"><Link to='section3' smooth='true' className='navIcon'><AiOutlineFundProjectionScreen/></Link></div>
            <div className="menuItem"><Link to='section4' smooth='true' className='navIcon'><BiBriefcase/></Link></div>
            <div className="menuItem"><Link to='section5' smooth='true' className='navIcon'><AiOutlinePhone/></Link></div>
          </div>
        </div>
  
        <div className="content">
          <div className="nav1"> </div>
          <div id="section1" className='section'>
            <div className="about">
              <div>  
                <h2 className='intro'>Hi. </h2>
                <h2 className='intro'>I'm Saheer,</h2>
                <TypeAnimation
                  cursor={true}
                  sequence={[
                    "Full Stack Developer",
                    1000,
                    "Machine Learning Engineer",
                    1000,
                    "Mobile App Developer",
                    1000,
                    "Front-End Developer",
                  ]}
                  wrapper="h2"
                  repeat={Infinity}
                  className="typing-text"
                />
              </div>
            </div>

            <div className="images">
              <div className="x1">
                <TbBrandJavascript className='y'/>  
              </div>
              <div className="x2">
                <FaJava className='y'/>
              </div>    
              <div className="x3">
                <FaPython className='y'/>
              </div>    
              <div className="x4">
                <SiMongodb className='y'/>
              </div>
              <div className="x5">
                <FaAws className='y'/>
              </div>
              <div className="x6">
                <SiMysql className='y'/>
              </div>
              <div className="x7">
                <DiBootstrap className='y'/>
              </div>
              <div className="x8">
                <DiDocker className='y'/>
              </div>   
              <div className="x9">
                <SiCplusplus className='y'/>
              </div>
            </div>

          </div>
          <div className="nav2"> </div>
          <div id="section2" className='section'>

            <div className="aboutMe">
              <img className='headshot' src={require("./Images/headshot.jpg")} />
              <h1>Saheer Ahmad</h1>
              <p>Aspiring software engineer with proven educational and professional experience.
                I am very interested in Finance, Machine Learning, Artificial Intelligence, and Computer Vision. 
                Passionate about building software that makes people's lives easier!
                I’m actively looking for internship opportunities for Fall 2023.  
              </p>
            </div>

            <div className='education'>
              <img src={require("./Images/Purdue-University-Logo.png")}></img>
              <p>Currently a Junior studying Computer Science with a focus is Software Engineering and Machine Learning. Expected to Graduate in Fall 2023</p>
            </div>

            
          </div>
          <div className="nav3"> </div>
          <div id="section3" className='section'>
            <ProjCarousel/>
          </div>
          <div className="nav4"> </div>
          <div id="section4" className='section'>
            <ExpCarousel/>
          </div>
          <div className="nav5"> </div>
          <div id="section5" className='section'>
          <form class="form" ref={form} onSubmit={sendEmail}>
            <div class="form-group">
                <label for="name">Name</label>
                <input
                required
                  type="name"
                  name="name"
                  class="form-control"
                  id="name"
                  placeholder="enter your name"
                />
              </div>

              {/* <!-- email --> */}
              <div class="form-group">
                <label for="email">Email address</label>
                <input
                required
                  type="email"
                  name="email"
                  class="form-control"
                  id="email"
                  placeholder="enter your email"
                />
              </div>

              <div class="form-group">
                <label for="subject">Subject</label>
                <input
                required
                  type="text"
                  name="subject"
                  class="form-control"
                  id="subject"
                  placeholder="enter email subject"
                />
              </div>

              <div class="form-group">
                <label for="message">Message</label>
                <textarea
                  required
                  type="text"
                  class="form-control-message"
                  id="message"
                  rows="5"
                ></textarea>
              </div>
              <button type="submit" class="submitButton">
              Submit
            </button>
          </form>
          </div>
        </div>

        <div className="socials">
          <div className="smallMenuSocials">
            <div className="socialsItem"><a className='socialIcon' href="https://www.linkedin.com/in/saheer-ahmad/" target="_blank"><GrLinkedinOption/></a></div>
            <div className="socialsItem"><a className='socialIcon' href="https://github.com/saheerahmad" target="_blank"><GrGithub/></a></div>
            <div className="socialsItem"><a className='socialIcon' href="mailto:saheerahmad1@gmail.com"><GrMail/></a></div>
          </div>
        </div>
        
      </div>
    );  
}

export default App;
