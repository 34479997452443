import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function ExpCarousel(){

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '60px',
      
    };

    return(
        <div className='sliderCon'>
            <Slider className='slider' {...settings}>
                <div className="expCard">
                    <img className='logo' src={require("./Images/Purdue.png")}></img>
                    <h2>Purdue University</h2>
                    <h3>IT Student Technician</h3>
                    <p>
                        • Administer inventory of 500+ hardware and software, including deployment and retirement, to ensure control of technological assets through Microsoft Endpoint Configuration Manager (SCCM).<br />
                        • Analyze over 50+ complex IT issues and provide technical support (setup, imaging, configuration, replacement, and more).<br />
                        • Respond and resolve over 50+ customer issues and escalations tickets per week for all end users to establish greater customer satisfaction via email, ticketing system, phone calls, or in person.<br />
                    </p>
                </div>
                <div className="expCard">
                    <img className='logo' src={require("./Images/GoBuddy.png")}></img>
                    <h2>GoBuddy</h2>
                    <h3>Mobile App Developer</h3>
                    <p>
                        • Working an AGILE team of 8 to create the GoBuddy app from scratch using React Native, NodeJS, and MongoDB. <br />
                        • Discussing and implementing features based on user needs and wants.<br/>
                        • Created an API to setup Users, Activities, and other basic functionality.<br/>
                        • Currently creating user interface based on UI/UX design plans. <br />
                    </p>
                </div>
                <div className="expCard">
                    <img src={require("./Images/RenzoeBox.png")}></img>
                    <h2>Renzoe Box</h2>
                    <h3>Machine Learning Intern</h3>
                    <p>
                        • Coordinated an AGILE SCRUM sub-team of 4 to get the Renzoe Box product ready for market<br />
                        • Developed a Natural Language Processing algorithm that boosted the accuracy from 94% to 98% over a span of 2 weeks <br />
                    </p>                    
                </div>
                <div className="expCard">
                    <img src={require("./Images/Popular.jpg")}></img>
                    <h2>Popular Diagnostics Ltd.</h2>
                    <h3>Software Developement Intern</h3>
                    <p>
                        • Collaborated with experienced Software Engineers using Full Stack technologies to keep their 26 hospitals running smoothly<br />
                        • Facilitated monthly meetings with stakeholders and users to discuss improvements and address any needs<br />
                        • Designed and implemented a task management software from scratch that is currently being used at 3 of their hospitals<br />
                        • Engaged in weekly maintenance and updates to their existing website and POS systems<br />
                    </p>
                </div>

            </Slider>
        </div>
    );
}

export default ExpCarousel;