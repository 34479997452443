import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function ProjCarousel(){

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '60px',
      
    };

    return(
        <div className='sliderCon'>
            <Slider className='slider' {...settings}>
                <div className="card">
                    <img style={{height:'30%'}} src={require("./Images/GoodCandy.png")}></img>
                    <h2>GoodCandy</h2>
                    <p style={{"font-size":'80%'}}>
                    • Created a Full Stack app for reviewing Houses to find the best neighborhood to trick or treat for a 24 hours Hackathon.<br />
                    • Frontend created using React, Database was created using MongoDB, linked using NodeJS and was hosted on Heroku and Microsoft Azure.<br />
                    • Created RESTFUL API’s to store and get user reviews and user authentication data for frontend cards.<br />
                    • Used LEAFLET API to get Map to mark houses and look through reviews.<br />
                    • Used auth0 for user authentication and two-factor verification.<br />
                    • Won DemonHacks 2022, a MajorLeague Hacking Hackathon<br />
                    </p>
                </div>
                <div className="card">
                    <img src={require("./Images/BetBall.png")}></img>
                    <h2>BetBall</h2>
                    <p>
                        • Developed a Web App that allows users to place bets on basketball games using Javascript, NodeJS, ExpressJS and mySQL.<br />
                        • Uses an API to get betting data for basketball games and updates it every minute.<br />
                        • Database is hosted on Google Cloud and is updated in real time.<br />

                    </p>
                </div>
                <div className="card">
                    <img src={require("./Images/EasyBook.png")}></img>
                    <h2>EasyBook</h2>
                    <p>
                        • Built a flight booking system that uses BFS to find the best route of travel, reducing the cost/time compared to direct flights<br />
                        • Efficiently finds the cheapest route as well as the fastest route<br />
                        • Written in Java <br />
                    </p>
                </div>
                <div className="card">
                    <img src={require("./Images/TradingAlgo.png")}></img>
                    <h2>Trading Algo</h2>
                    <p>
                        • Developed an algorithm that analyses SIMFIN data from 2008 to 2020 to discover patterns which are then used as input in a Machine Learning algorithm that predicts the future stock prices<br />
                        • Coded in Python<br />
                    </p>
                </div>
            </Slider>
        </div>
    );
}

export default ProjCarousel;